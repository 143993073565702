vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Mesoterapia e intralipoterapia</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="intralipoterapia" src="../../assets/intralipoterapia.svg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            La <span class="p-text-bold">mesoterapia</span> es un tratamiento estético que consiste en la
                            aplicación de pequeñas inyecciones de sustancias lipolíticas y reafirmantes en la capa media de
                            la piel. Estas sustancias ayudan a reducir la grasa localizada y mejorar la apariencia de la
                            piel. La mesoterapia se realiza de forma personalizada, adaptándose a las necesidades de cada
                            paciente y a las áreas a tratar.
                        </p>
                        <p>
                            Por otro lado, la <span class="p-text-bold">intralipoterapia</span> es un procedimiento que
                            consiste en la infiltración de una solución acuosa que contiene ácido desoxicólico en las
                            células de grasa localizada. Este ácido descompone las células grasas, lo que resulta en una
                            reducción de volumen en la zona tratada. La intralipoterapia se utiliza principalmente para
                            tratar la grasa localizada en áreas como el abdomen, los muslos y los brazos.
                        </p>
                        <p>
                            Ambos tratamientos son mínimamente invasivos y se realizan en consultorio médico. Los resultados
                            pueden variar según cada paciente, pero en general, se observa una reducción de la grasa
                            localizada y una mejora en la apariencia de la piel. Es importante tener en cuenta que estos
                            tratamientos deben ser realizados por profesionales capacitados y contar con una evaluación
                            médica previa.
                        </p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'IntralipoterapiaVue',

    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>